// pages/index.tsx
import Image from "next/image";
import SectionTitle from "components/atoms/SectionTitle";
import { AboutLeaderboardFull } from "images";
import { BorderWrapper, Container, FlexItem, FlexWrapper, ImageWrapper, Section } from "./AboutSection.styled";
const AboutSectionThree = () => {
  return <Section id="about" data-sentry-element="Section" data-sentry-component="AboutSectionThree" data-sentry-source-file="index.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <FlexWrapper data-sentry-element="FlexWrapper" data-sentry-source-file="index.tsx">
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <SectionTitle title="Compete on the Leaderboard for Monthly Prizes." paragraph="Join the friendly competition on our leaderboard, where users can vie for the top spot each month. Collect votes on your AI-generated D&D characters, NPCs, and monsters. Compete to be among the top creators using our AI character creator, D&D character maker, and AI RPG generator. The highest voted users each month win free Gold, allowing access to premium features like high-quality renders, face fixing, and exclusive themes." data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                    </FlexItem>
                    <FlexItem data-sentry-element="FlexItem" data-sentry-source-file="index.tsx">
                        <ImageWrapper data-sentry-element="ImageWrapper" data-sentry-source-file="index.tsx">
                            <Image src={AboutLeaderboardFull} alt="Leaderboard displaying users' total character generations and votes for the current month." style={{
              width: "100%",
              height: "auto",
              objectFit: "contain"
            }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        </ImageWrapper>
                    </FlexItem>
                </FlexWrapper>
            </Container>
            <BorderWrapper data-sentry-element="BorderWrapper" data-sentry-source-file="index.tsx" />
        </Section>;
};
export default AboutSectionThree;