// components/HeroStyles.ts
import styled from "styled-components";

import { Button as StyledButton } from "components/atoms/Button";
import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Text, Title as StyledTitle } from "utils/styling/typography.styled";

export const Container = styled.div`
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 100vw;
    background: linear-gradient(180deg, #161727 0%, #383fb3 100%);
`;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
`;

export const Content = styled.div`
    width: 75%;
    padding-top: 4rem;

    ${device.md} {
        padding-top: 5rem;
    }

    ${device.lg} {
        padding-top: 7rem;
    }
    margin: 1rem auto;
    text-align: center;
    animation: fadeInUp 0.2s ease-in-out;

    ${device.lg} {
        width: 50%;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 40px, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`;

export const Title = styled(StyledTitle)`
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;

    ${device.md} {
        font-size: 2.5rem;
    }

    ${device.lg} {
        font-size: 3rem;
    }

    ${device.xl} {
        font-size: 3.5rem;
    }
`;

export const Description = styled(Text)`
    margin-bottom: 3rem;
    font-size: 0.875rem;
    line-height: 1.75;
    color: ${colourScheme.white};

    ${device.md} {
        font-size: 1rem;
    }

    ${device.lg} {
        font-size: 1.125rem;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    ${device.sm} {
        flex-direction: row;
        gap: 1rem;
    }
`;

export const Button = styled(StyledButton)`
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: white;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease-in-out;
    text-align: center;
    border: none;
`;
