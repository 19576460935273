import styled from "styled-components";

import { Button as StyledButton } from "components/atoms/Button";
import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    min-height: calc(100vh - (5rem + 8rem));
`;

export const Section = styled.section`
    width: 100%;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colourScheme.primary};
`;

export const Button = styled(StyledButton)`
    margin-top: -1rem;
    padding: 1rem 2rem;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 1rem;

    ${device.md} {
        font-size: 1.5rem;
    }
`;
