// components/Features/Feature.styled.ts
import Image from "next/image";
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Text } from "utils/styling/typography.styled";

export const FeaturesSection = styled.section`
    width: 100%; // Ensure it takes full width
`;

export const Container = styled.div`
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

export const ArchWrapper = styled.div`
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;

    & img {
        width: 100%;
        height: auto;
        position: relative;
        top: -1px;
    }
`;

export const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    max-width: 40rem;
`;

export const FeaturesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    width: 80%;
    margin: 0 auto 2rem;
    ${device.md} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${device.lg} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const SingleFeatureWrapper = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
export const FeatureIcon = styled(Image)`
    margin-bottom: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;

    width: 100%;
    max-width: 20rem;
    height: auto;
`;

export const FeatureTitle = styled.h3`
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    font-weight: bold;
    font-family: inherit;
    ${device.md} {
        font-size: 1.5rem;
    }

    ${device.lg} {
        font-size: 1.75rem;
    }
`;

export const FeatureParagraph = styled(Text)`
    font-size: 1rem;
    color: ${colourScheme.grey};
    text-align: justify;
    text-justify: inter-word;

    ${device.md} {
        font-size: 0.75rem;
    }

    ${device.lg} {
        font-size: 1rem;
    }
`;
