// pages/index.tsx
import Image from "next/image";
import SectionTitle from "components/atoms/SectionTitle";
import { FeaturesEasyArtGeneration, FeaturesEasyToUse, FeaturesGallery, FeaturesMonsterGenerator, FeaturesStatBlock, FeatureThemes, HeroFeaturesImage } from "images";
import Arch from "./arch.svg";
import { ArchWrapper, Container, FeatureIcon, FeatureParagraph, FeaturesContainer, FeaturesSection, FeatureTitle, ImageWrapper, SingleFeatureWrapper } from "./Feature.styled";
const Features = () => {
  return <FeaturesSection id="features" data-sentry-element="FeaturesSection" data-sentry-component="Features" data-sentry-source-file="index.tsx">
            <ArchWrapper data-sentry-element="ArchWrapper" data-sentry-source-file="index.tsx">
                <Image loading="eager" src={Arch} alt="arch" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            </ArchWrapper>
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <ImageWrapper data-sentry-element="ImageWrapper" data-sentry-source-file="index.tsx">
                    <Image src={HeroFeaturesImage} alt="Chargen Homescreen displaying a generated D&D character on a laptop, showcasing AI Art and Character Generation for Dungeons & Dragons" loading="eager" priority width={640} height={365} style={{
          width: "100%",
          height: "auto"
        }} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                </ImageWrapper>
                <SectionTitle title="Generate Art for Free!" paragraph="                        CharGen makes it simple to create stunning art for your
                        D&D characters, NPCs, and monsters with our AI character
                        creator. Whether you are looking for a D&D monster
                        maker, an AI RPG generator, or the best AI image
                        generator for your fantasy campaigns, CharGen has you
                        covered. Experience the best D&D character creator and
                        generate captivating fantasy AI art effortlessly." center data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                <FeaturesContainer data-sentry-element="FeaturesContainer" data-sentry-source-file="index.tsx">
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeaturesEasyArtGeneration} width={225} height={320} style={{
            width: "100%",
            height: "auto"
          }} alt="Pathfinder RPG character generated by Chargen's AI displayed alongside the intuitive AI character generation interface" data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            Simple and Intuitive Art Generation
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            No need for complex prompts or artistic knowledge.
                            With our easy-to-use interface, creating
                            AI-generated art for your campaigns is as simple as
                            a few clicks. Utilize our AI D&D character generator
                            and monster AI generator to bring your fantasy
                            worlds to life with the best fantasy image generator
                            available.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeaturesEasyToUse} width={250} height={320} alt="AI-generated monsters watercolour ghosts with appearance and description information, demonstrating advanced AI art generation" style={{
            width: "100%",
            height: "auto"
          }} data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            Effortless AI Generated Fantasy Art
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            Turn your RPG ideas into stunning, detailed fantasy
                            art with AI. CharGen makes it simple to generate art
                            for characters, NPCs, monsters, and more, without
                            needing any artistic skills. Use our AI photo
                            generator and free AI image generator to bring your
                            Dungeons and Dragons campaigns to life.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeaturesGallery} width={225} height={320} style={{
            width: "100%",
            height: "auto"
          }} alt="Gallery of AI-generated NPCs, characters, and monsters created by users" data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            Gallery of AI-Generated NPCs, Characters, and
                            Monsters Created by Users
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            Showcase Your Creations in the User Gallery. Share
                            your artwork with the CharGen community through our
                            user gallery. Explore and vote on others creations,
                            see the settings and prompts they used, and get
                            inspired by the diverse array of AI generated images
                            and fantasy art from fellow users.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeaturesStatBlock} alt="Enhanced Monster Creator with our Stat Block
                            Generator for D&D Monsters" width={240} height={320} style={{
            width: "100%",
            height: "auto"
          }} data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            {" "}
                            Enhanced Monster Creator with our Stat Block
                            Generator
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            Effortlessly create monster stat blocks with our
                            advanced generator. Simply provide a CR level, and
                            you can generate detailed stat blocks within
                            seconds, perfectly tailored to your created
                            monsters. This feature is designed to complement our
                            D&D monster maker and AI monster generator,
                            streamlining your monster creation process and
                            enhancing your game preparation. Experience seamless
                            integration of accurate and customized monster stats
                            into your D&D campaigns with just a few clicks.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeatureThemes} alt="Dropdown menu of themes and art styles available in CharGen for AI art generation" width={240} height={320} style={{
            width: "100%",
            height: "auto"
          }} data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            Hundreds of Themes and Art Styles
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            Choose from hundreds of themes and art styles to
                            match your campaign aesthetic. Whether you prefer
                            dark and gritty or bright and whimsical, CharGen has
                            the right style to bring your RPG world to life with
                            our AI image creator. Enjoy a large selection of
                            generation models including Stable Diffusion SDXL,
                            User Created Models, DallE3, and now even
                            introducing the new Flux models such as Flux.Pro and
                            more to customize your fantasy art even further.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                    <SingleFeatureWrapper data-sentry-element="SingleFeatureWrapper" data-sentry-source-file="index.tsx">
                        <FeatureIcon src={FeaturesMonsterGenerator} width={213.3333333333} height={320} alt="CharGen AI-generated Monsters showing a Gelatinous Cube and race and monster generation options" style={{
            width: "100%",
            height: "auto"
          }} data-sentry-element="FeatureIcon" data-sentry-source-file="index.tsx" />
                        <FeatureTitle data-sentry-element="FeatureTitle" data-sentry-source-file="index.tsx">
                            Diverse Races, Classes, and Monster Types
                        </FeatureTitle>
                        <FeatureParagraph data-sentry-element="FeatureParagraph" data-sentry-source-file="index.tsx">
                            Create art for any character, class, or creature
                            with our extensive library of options. From classic
                            fantasy races to unique monster types, CharGen
                            offers a wide range of choices to fit any campaign
                            setting. Experience the best D&D character maker and
                            dungeons and dragons character creator available.
                        </FeatureParagraph>
                    </SingleFeatureWrapper>
                </FeaturesContainer>
            </Container>
        </FeaturesSection>;
};
export default Features;