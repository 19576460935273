// components/Testimonials/index.tsx
import Image from "next/image";
import { StarIcon } from "components/atoms/Icon";
import SectionTitle from "components/atoms/SectionTitle";
import { TestimonialBuclear, TestimonialKrackle, TestimonialLogan } from "images";
import { Container, GridWrapper, Section, StarWrapper, TestimonialCard, TestimonialText, UserImage, UserInfo, UserWrapper } from "./Testimonials.styled";
const Testimonials = () => {
  const renderStars = () => {
    return Array.from({
      length: 5
    }, (_, i) => <Image key={i} src={StarIcon} alt="Star Icon" />);
  };
  return <Section data-sentry-element="Section" data-sentry-component="Testimonials" data-sentry-source-file="index.tsx">
            <Container data-sentry-element="Container" data-sentry-source-file="index.tsx">
                <SectionTitle title="See What Our Users Are Saying" paragraph="Hear from Our Community of TTRPG Enthusiasts." center data-sentry-element="SectionTitle" data-sentry-source-file="index.tsx" />
                <GridWrapper data-sentry-element="GridWrapper" data-sentry-source-file="index.tsx">
                    <TestimonialCard data-sentry-element="TestimonialCard" data-sentry-source-file="index.tsx">
                        <StarWrapper data-sentry-element="StarWrapper" data-sentry-source-file="index.tsx">{renderStars()}</StarWrapper>
                        <TestimonialText data-sentry-element="TestimonialText" data-sentry-source-file="index.tsx">
                            As a GM, Char-gen has given me the tools to really
                            enrich my campaigns. Ive created images for player
                            characters, NPCs, monsters and even scenes. These
                            handouts have really helped to set the scene online
                            or around the table.
                        </TestimonialText>
                        <UserWrapper data-sentry-element="UserWrapper" data-sentry-source-file="index.tsx">
                            <UserImage data-sentry-element="UserImage" data-sentry-source-file="index.tsx">
                                <Image src={TestimonialLogan} alt="Testimonial from a Game Master who used CharGen to generate NPCs for their game." height={96} width={96} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                            </UserImage>
                            <UserInfo data-sentry-element="UserInfo" data-sentry-source-file="index.tsx">
                                <h3>Logan XVII</h3>
                                <p>Game Master</p>
                            </UserInfo>
                        </UserWrapper>
                    </TestimonialCard>
                    <TestimonialCard data-sentry-element="TestimonialCard" data-sentry-source-file="index.tsx">
                        <StarWrapper data-sentry-element="StarWrapper" data-sentry-source-file="index.tsx">{renderStars()}</StarWrapper>
                        <TestimonialText data-sentry-element="TestimonialText" data-sentry-source-file="index.tsx">
                            As someone who loves creating unique characters for
                            my campaigns, CharGen has been a game-changer. The
                            AI-generated art is incredible, and the ability to
                            customize every detail means my characters look
                            exactly how I envision them.
                        </TestimonialText>
                        <UserWrapper data-sentry-element="UserWrapper" data-sentry-source-file="index.tsx">
                            <UserImage data-sentry-element="UserImage" data-sentry-source-file="index.tsx">
                                <Image src={TestimonialKrackle} alt="Testimonial from a D&D player who created characters using CharGens AI generation." height={96} width={96} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                            </UserImage>
                            <UserInfo data-sentry-element="UserInfo" data-sentry-source-file="index.tsx">
                                <h3>Krackle Brightbeak</h3>
                                <p>D&D Player</p>
                            </UserInfo>
                        </UserWrapper>
                    </TestimonialCard>
                    <TestimonialCard data-sentry-element="TestimonialCard" data-sentry-source-file="index.tsx">
                        <StarWrapper data-sentry-element="StarWrapper" data-sentry-source-file="index.tsx">{renderStars()}</StarWrapper>
                        <TestimonialText data-sentry-element="TestimonialText" data-sentry-source-file="index.tsx">
                            The free daily art generations are fantastic! I love
                            that I dont need to subscribe to get high-quality
                            character and monster art. CharGen has made my game
                            preparation so much easier and more enjoyable.
                        </TestimonialText>
                        <UserWrapper data-sentry-element="UserWrapper" data-sentry-source-file="index.tsx">
                            <UserImage data-sentry-element="UserImage" data-sentry-source-file="index.tsx">
                                <Image src={TestimonialBuclear} alt="Testimonial from a Pathfinder RPG player who utilized CharGens AI generation for character creation." height={96} width={96} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                            </UserImage>
                            <UserInfo data-sentry-element="UserInfo" data-sentry-source-file="index.tsx">
                                <h3>Buclear</h3>
                                <p>Pathfinder Player</p>
                            </UserInfo>
                        </UserWrapper>
                    </TestimonialCard>
                </GridWrapper>
            </Container>
        </Section>;
};
export default Testimonials;