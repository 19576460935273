// components/Testimonials/testimonials.styled.ts
import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const Section = styled.section`
    position: relative;
    z-index: 10;
    background-color: ${colourScheme.primaryDark};
    padding: 4rem 0;
    max-width: 100vw;
    width: 100%;

    ${device.md} {
        padding: 5rem 0;
    }

    ${device.lg} {
        padding: 7rem 0;
    }
`;

export const Container = styled.div`
    padding: 0 1rem;
    margin: 0 auto;
    align-items: center;
`;

export const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    max-width: 75%;
    margin: 0 auto;

    ${device.md} {
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5rem;
    }

    ${device.lg} {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }
`;

export const TestimonialCard = styled.div`
    background-color: ${colourScheme.primaryLight};
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 20px rgba(75, 78, 110, 0.6);
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 10px 40px rgba(75, 78, 110, 0.8);
    }
    ${device.md} {
        padding: 2.5rem;
    }

    ${device.lg} {
        padding: 3rem;
    }
`;

export const StarWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    span {
        color: ${colourScheme.alert};
        margin-right: 0.25rem;
    }
`;

export const TestimonialText = styled.p`
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    line-height: 1.5;
    color: ${colourScheme.white};
    text-align: justify;
    text-justify: inter-word;

    ${device.md} {
        font-size: 1.125rem;
    }
`;

export const UserWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UserImage = styled.div`
    position: relative;
    margin-right: 1rem;
    aspect-ratio: 1;
    width: 2rem;
    overflow: hidden;
    border-radius: 50%;
    flex: 0 0 6rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const UserInfo = styled.div`
    h3 {
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-weight: 600;
        color: ${colourScheme.fullWhite};

        ${device.lg} {
            font-size: 1.25rem;
        }
    }

    p {
        font-size: 0.875rem;
        color: ${colourScheme.grey};

        ${device.lg} {
            font-size: 1rem;
        }
    }
`;
